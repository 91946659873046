import React, { createContext } from 'react';


import { GroupAndUserService } from './GroupAndUsers/group-and-user-service';


export const ServiceContext = createContext();

const ServiceContextProvider = (props) => {

 
    const GroupAndUser = GroupAndUserService()

    return (
        <ServiceContext.Provider value={{ GroupAndUser }}> 
            { props.children}
        </ServiceContext.Provider>
    )
}

export default ServiceContextProvider;
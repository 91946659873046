import React, { useState, useEffect, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, Grid, IconButton, CircularProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
//import TextFields from '@material-ui/icons/TextFields';
import * as apiService from '../../../service/api';
import * as subscriptions from '../../../graphql/subscriptions';
import { AuthContext } from '../../../context/auth/auth-context';



const TextSimilarity = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false)
    const [bucketKey, setBucketKey] = useState(null)
    const [processResult, sepProcessResult] = useState(null)
    const [processing, setProcessing] = useState(false)
    const [image1, setImage1] = useState(null)
    const [image2, setImage2] = useState(null)

    /*
    const [textInput, setTextInput] = useState({
        inputText1: '',
        inputText2: ''
    })
    */
    const { isAuthenticated } = useContext(AuthContext);



    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        maxSize: 10000000,
        accept: {
            'application/vnd.ms-excel': ['.xlsx'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml': ['.sheet'],
            'text/csv': ['.csv']
        },
        onDrop: acceptedFiles => {
            setFile(acceptedFiles[0])
        },
        onDropRejected: rejectedFiles => {
            console.error(rejectedFiles)
        }
    });



    const uploadFileHandler = async () => {
        try {
            setUploading(true)
            setProcessing(false)
            let path = '';
            let filename = `${new Date().getTime()}_${file.name}`;
            path = path.concat(filename)
            const { key } = await apiService.uploadFile(path, file, null)
            setBucketKey(key)
            setUploading(false)
        } catch (error) {
            setUploading(false)
        }
    }

    const processFileHandler = async () => {
        try {
            setProcessing(true)
            const _s3FilePath = await apiService.getFile(bucketKey)
            const data = { s3FilePath: _s3FilePath }
            await apiService.ProcessTextSimilarity(data)
        } catch (error) {
            setProcessing(false)
            console.error(error)
        }
    }


    useEffect(() => {
        let subscription;
        const onPageRendered = async () => {
            console.log('Subscription. . . ')
            subscription = API.graphql(
                graphqlOperation(subscriptions.onProcessTextSimilarityFinish)
            ).subscribe({
                next: async ({ provider, value }) => {
                    const { s3FilePath } = value.data.onProcessTextSimilarityFinish
                    sepProcessResult(s3FilePath)
                    const _image1 = await apiService.getFile('desc_output.png')
                    const _image2 = await apiService.getFile('resumo_output.png')
                    setImage1(_image1)
                    setImage2(_image2)
                    setProcessing(false)
                },
                error: error => {
                    console.error(error)
                    setProcessing(false)
                }
            });
            console.log(subscription)
        }
        onPageRendered()
        return () => {
            subscription.unsubscribe();
        };
    }, [isAuthenticated])






    return (
        <Container maxWidth="lg" className={classes.container}>
            <div className={classes.headerBox}>
                <h1>{t('text-similarity-title')}</h1>
                <p>
                    {t('text-similarity-description')}
                </p>
            </div>
            {isAuthenticated ? (
                <Grid container spacing={6}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <section>
                            <div {...getRootProps({ className: classes.dropzone })}>
                                <input {...getInputProps()} />
                                <div className={classes.infoBox}>
                                    <h3>{t('select file')}</h3>
                                    <p>{t('drag files info')}</p>
                                    <span>{t('drag files type info')}</span>
                                </div>
                            </div>
                        </section>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={!file}
                            onClick={uploadFileHandler}>
                            {uploading ? <CircularProgress size={24} color={'inherit'} /> : t('upload file')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        {file ? (
                            <div className={classes.infoFileBox}>
                                <p>{t('file name')}: <span>{file?.name}</span></p>
                                <p>{t('file type')}: <span>{file?.type}</span></p>
                                <p>{t('file size')}: <span>{Number(file?.size / 1000000).toFixed(2)} MB</span></p>
                                <div className={classes.actionBox}>
                                    <Button
                                        onClick={processFileHandler}
                                        color="secondary"
                                        variant="contained"
                                        disabled={!bucketKey}
                                        style={{ maxWidth: '120px', marginLeft: 'auto', marginRight: 'auto' }}>
                                        {processing ? <CircularProgress size={24} color={'inherit'} /> : t('process')}
                                    </Button>
                                    <IconButton
                                        onClick={() => {
                                            setBucketKey(null)
                                            setFile(null)
                                        }}
                                        style={{ margin: 'auto 10px' }}
                                        aria-label="delete">
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </div>
                            </div>
                        ) : (
                            <div className={classes.emptyFileBox}>
                                {t('text-similarity-no-file-info')}
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {processResult && (
                            <div className={classes.imageResultBox}>
                                <h4>{t('text-similarity-result-title')}</h4>
                                <img src={image1} alt="" />
                                <img src={image2} alt="" />
                            </div>
                        )}
                    </Grid>
                </Grid>

                /*
           <Grid container spacing={6} style={{paddingTop: '40px'}}>
               <Grid item xs={6}>
                   <TextField
                       id="inputText1"
                       type="text"
                       label={t("text")}
                       placeholder={t("text")}
                       variant="outlined"
                       InputProps={{
                           startAdornment: (
                               <TextFields className={classes.icons} />
                           ),
                       }}
                       onChange={(event) => {
                           setTextInput({
                               ...textInput,
                               inputText1: event.target.value
                           })
                       }}
                       multiline
                       rows={5}
                       classes={{
                           root: classes.textArea,
                       }}
                   />
               </Grid>
               <Grid item xs={6}>
                   <TextField
                       id="inputText2"
                       type="text"
                       label={t("text")}
                       placeholder={t("text")}
                       variant="outlined"
                       InputProps={{
                           startAdornment: (
                               <TextFields className={classes.icons} />
                           ),
                       }}
                       onChange={(event) => {
                           setTextInput({
                               ...textInput,
                               inputText2: event.target.value
                           })
                       }}
                       multiline
                       rows={5}
                       classes={{
                           root: classes.textArea,
                       }}
                   />
               </Grid>
               <Button
                   color="primary"
                   variant="contained"
                   disabled={!textInput.inputText1.length || !textInput.inputText2.length}
                   onClick={uploadFileHandler}>
                   {false ? <CircularProgress size={24} color={'inherit'} /> : t('compare')}
               </Button>
           </Grid>
             */
            ) : (
                <h4 style={{ textAlign: 'center', color: 'rgba(176, 25, 26, 1)' }}>{t('required login app info')}</h4>
            )}
        </Container>
    );
}

export default TextSimilarity;

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: '50px',
        paddingBottom: '30px',
        marginLeft: '40px',
        marginRight: '40px'
    },
    headerBox: {
        textAlign: 'center',
        paddingBottom: '40px',
        '& p': {
            whiteSpace: 'pre-line'
        }
    },
    dropzone: {
        border: '1px dashed rgba(176, 25, 26, 0.7)',
        display: 'flex',
        outline: 'none',
        padding: '20px',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer',
            opacity: 0.9,
            backgroundColor: 'rgba(176, 25, 26, 0.5)',
            color: '#FFF'
        }
    },
    infoFileBox: {
        width: '100%',
        padding: '0 20px',
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex',
        '& p': {
            textAlign: 'left',
            fontSize: '14px',
            fontWeight: 700,
            '& span': {
                fontWeight: 400,
                fontSize: '12px',
            }
        }
    },
    infoBox: {
        '& span': {
            color: 'rgba(176, 25, 26, 1)',
            fontWeight: 600,
            fontSize: '11px'
        }
    },
    emptyFileBox: {
        width: '100%',
        padding: '0 20px'
    },
    actionBox: {
        justifyContent: 'flex'
    },
    icons: {
        marginRight: "10px",
        color: theme.palette.primary.main
    },
    textArea: {
        "& .MuiOutlinedInput-root": {
            width: '100% !important',
            height: '100% !important',
            alignItems: 'flex-start'
        },
    },
    imageResultBox: {
        justifyContent: 'center',
        display: 'flex',
        margin: '20px auto',
        flexDirection: 'column'
    }
}))

import { API, graphqlOperation, Auth, Storage } from "aws-amplify";
import { listBoModules, listUser, listGroup, getUser } from '../../graphql/queries';
import { createGroup, createUser, updateUser, removeUserFromGroup, processTextSimilarity } from "../../graphql/mutations";
import { onCreateBoModule } from "../../graphql/subscriptions";

export const listModules = async () => {
    return await API.graphql(graphqlOperation(listBoModules));
}

export const CreateGroup = async (data) => {
    return await API.graphql(graphqlOperation(createGroup, data));
};

export const ListUsers = async () => {
    return await API.graphql(graphqlOperation(listUser));
};

export const ListGroups = async () => {
    return await API.graphql(graphqlOperation(listGroup));
};

export const CreateUser = async (data) => {
    return await API.graphql(graphqlOperation(createUser, data));
};

export const UpdateUser = async (data) => {
    return await API.graphql(graphqlOperation(updateUser, data));
};

export const GetUser = async (data) => {
    return await API.graphql(graphqlOperation(getUser, data));
};

export const RemoveUserFromGroup = async (data) => {
    return await API.graphql(graphqlOperation(removeUserFromGroup, data));
};

export const ProcessTextSimilarity = async (data) => {
    return await API.graphql(graphqlOperation(processTextSimilarity, {input : data}));
};


/************************************************ STORAGE ***********************************************/

export const getFile = async (key, bucket) => {
    console.log(key)
    if (bucket) {
        return Storage.get(key, {
            bucket: bucket
        })
    } else {
        return Storage.get(key)
    }
}

export const removeItemStorage = async (key, bucket) => {
    if (bucket) {
        return Storage.remove(key, {
            bucket: bucket
        })
    } else {
        return Storage.remove(key)
    }
}


export const uploadFile = async (fullPath, file, bucket) => {
    console.log(bucket, fullPath)
    if (bucket) {
        return Storage.put(fullPath, file, {
            bucket: bucket
        })
    } else {
        return Storage.put(fullPath, file, { contentType: file.type })
    }
}



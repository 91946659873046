import React from 'react';
import { TextField, Paper, FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextFields, Subject } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Styles from './style';
import SubmitButton from '../../../components/Shared/SubmitButton';


const useStyles = makeStyles(Styles);

const Form = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const createForm = useFormik({
        initialValues: {
            field1: '',
            field2: '',
            field3: '',
            active: true,
            online: false
        },
        onSubmit: async (values) => {
            try {
                console.log(values)
            } catch (error) { }
        }
    });


    return (
        <div className={classes.root}>
            <Paper elevation={0} className={classes.paper}>
                <form onSubmit={createForm.handleSubmit} className={classes.form} >
                    <TextField
                        id="field1"
                        type='text'
                        label={t('form-create-input-field1')}
                        placeholder={t('form-create-input-field1')}
                        variant="outlined"
                        InputProps={{ startAdornment: <TextFields className={classes.icons} /> }}
                        required
                    />

                    <TextField
                        id="field1"
                        type='text'
                        label={t('form-create-input-field2')}
                        placeholder={t('form-create-input-field2')}
                        variant="outlined"
                        InputProps={{ startAdornment: <Subject className={classes.icons} /> }}
                        rows={8}
                        multiline
                        classes={{
                            root: classes.textArea,
                        }}
                        required
                    />

                    <TextField
                        id="field3"
                        type='text'
                        label={t('form-create-input-field3')}
                        placeholder={t('form-create-input-field3')}
                        variant="outlined"
                        InputProps={{ startAdornment: <TextFields className={classes.icons} /> }}
                        required
                    />
                    <div className={classes.column}>
                        <div className={classes.switchBox}>
                            <FormControlLabel control={
                                <Switch
                                    color="primary"
                                    checked={createForm.values.active}
                                    onChange={(event) => createForm.setFieldValue('active', event.target.checked)}
                                />
                            }
                                label={t('form-create-input-active')}
                                style={{ marginRight: '40px' }}
                            />

                            <FormControlLabel control={
                                <Switch
                                    color="primary"
                                    checked={createForm.values.online}
                                    onChange={(event) => createForm.setFieldValue('online', event.target.checked)}
                                />
                            }
                                label={t('form-create-input-online')}
                                style={{ margin: '20px 0' }}
                            />
                        </div>
                    </div>
                    <SubmitButton
                        isSubmitting={createForm.isSubmitting}
                        label={t("create")}
                    />
                </form>
            </Paper>

        </div>
    );
}

export default Form;
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onProcessTextSimilarityFinish = /* GraphQL */ `
  subscription OnProcessTextSimilarityFinish {
    onProcessTextSimilarityFinish {
      s3FilePath
    }
  }
`;
export const onCreateBoModule = /* GraphQL */ `
  subscription OnCreateBoModule {
    onCreateBOModule {
      id
      name
      description
      enabled
      order
      submodules {
        id
        featureId
        featureType
        name
        description
        enabled
        order
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBoModule = /* GraphQL */ `
  subscription OnUpdateBoModule {
    onUpdateBOModule {
      id
      name
      description
      enabled
      order
      submodules {
        id
        featureId
        featureType
        name
        description
        enabled
        order
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBoModule = /* GraphQL */ `
  subscription OnDeleteBoModule {
    onDeleteBOModule {
      id
      name
      description
      enabled
      order
      submodules {
        id
        featureId
        featureType
        name
        description
        enabled
        order
      }
      createdAt
      updatedAt
    }
  }
`;

import React, { useContext } from 'react';

//Router
import { Routes, Route, Navigate } from 'react-router-dom';

import { AuthContext } from '../context/auth/auth-context';

//Layout
import PrivateLayoutBo from '../layout/Bo/private-layout-bo';
import PrivateLayoutApp from '../layout/App/private-layout-app';

//Screens
import OAuthRedirector from '../screens/OAuthRedirector';


import DashboardBo from '../screens/Backoffice/Dashboard';
import DashboardApp from '../screens/App/Dashboard';

import Groups from '../screens/Backoffice/AdminGroup/List';
import CreateGroup from '../screens/Backoffice/AdminGroup/Create';
import EditGroup from '../screens/Backoffice/AdminGroup/Edit';

import Users from '../screens/Backoffice/AdminUser/List';
import CreateUser from '../screens/Backoffice/AdminUser/Create';
import EditUser from '../screens/Backoffice/AdminUser/Edit';

import Todos from '../screens/Backoffice/Todo';
import Form from '../screens/Backoffice/Form';


import SignIn from '../screens/Auth/SignIn';
import RecoverPassword from '../screens/Auth/RecoverPassword';
import NewPasswordRequired from '../screens/Auth/NewPasswordRequired';
import RecoverPasswordConfirm from '../screens/Auth/RecoverPasswordConfirm';


import TextSimilarity from '../screens/App/TextSimilarity';

const AllRoutes = () => {

    const { isLoading } = useContext(AuthContext)

    if (isLoading) return null

    return (
        <Routes>
            <Route path="bo">
                <Route path='/bo' element={<Navigate to="/bo/dashboard" />} />
                <Route path="dashboard" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <DashboardBo />
                        </PrivateLayoutBo>
                    </RequireAuthBo>

                } />

                <Route path="groups" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <Groups />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="groups/create" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <CreateGroup />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="groups/edit/:slug" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <EditGroup />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />


                <Route path="users" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <Users />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="users/create" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <CreateUser />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="users/edit/:slug" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <EditUser />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="todos/*" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <Todos />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="form/*" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <Form />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />


                <Route path='*' element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <p>PAGE BO NO FOUND</p>
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="auth/sign-in" element={<SignIn />} />
                <Route path="auth/recover-password" element={<RecoverPassword />} />
                <Route path="auth/recover-password/confirm" element={<RecoverPasswordConfirm />} />
                <Route path="auth/new-password-required" element={<NewPasswordRequired />} />
                <Route path='*' element={<p>PAGE BO NO FOUND</p>} />
            </Route>



            <Route path="/" element={
                <RequireAuthApp>
                    <PrivateLayoutApp />
                </RequireAuthApp>}>
                <Route path='/' element={<TextSimilarity />} />
                <Route path='*' element={<Navigate to="/" />} />
            </Route>

            <Route path="oauth-redirector" element={<OAuthRedirector />} />

            <Route path='*' element={<p>NO FOUND</p>} />
        </Routes>
    )


}


const RequireAuthBo = ({ children }) => {

    const { isAuthenticated, groups, roleBo } = useContext(AuthContext)

    const isIncluded = roleBo?.some(value => groups?.includes(value)) || roleBo?.includes('*')

    if ( !isAuthenticated || !isIncluded) {
        return <Navigate to="/bo/auth/sign-in" state={{ from: '/bo/dashboard' }} replace />;
    }
    return children;
}


const RequireAuthApp = ({ children }) => {

    const { groups, roleApp, signout, isAuthenticated } = useContext(AuthContext)

    const isIncluded = roleApp?.some(value => groups?.includes(value)) || roleApp?.includes('*')

    if (!isIncluded && isAuthenticated) {
        signout()
    }
    return children;
}




export default AllRoutes;
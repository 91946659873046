import React from "react";

//Material
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { Box } from "@material-ui/core";




const Table = (props) => {

  return (
    <Box style={{ width: "100%" }}>
      <DataGrid
        loading={props.loading}
        rows={props.data}
        columns={props.columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        disableColumnMenu
        autoPageSize
        autoHeight
        editMode="row"
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
      />
    </Box>
  );
};

export default Table;

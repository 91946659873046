import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SideMenuStructer from '../config/sidemenu.config';
import { SideMenu, ToolbarMenu } from '../../components';
import Breadcrumbs from '../../components/Backoffice/Breadcrumbs';
import Notifications from '../../components/Shared/NotificationSnackbar';


import Styles from './style';
import { listModules } from '../../service/api';


const useStyles = makeStyles(Styles);

const PrivateLayoutBo = ({ children }) => {


    const classes = useStyles();
    const [sideMenu, setSideMenu] = useState([]);

    useEffect(() => {
        createSideMenu(SideMenuStructer);
    }, []);

    /**
     * Create the SideMenu component based on the modules return from de API request,
     * and filtered using the config file.
     * @param {array} structer 
     */
    const createSideMenu = async (structer) => {
        const modules = await listModules();
        /*const filteredModules = SideMenuStructer.filter(item => {
            return modules.data.listBOModules.items.some(module => item.id === module.id)
        });
        */
        const filteredModules = SideMenuStructer
        setSideMenu(filteredModules);
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <SideMenu structer={sideMenu} />
                <div className={classes.contentContainer}>
                    <div className={classes.toolbarContainer}>
                        <ToolbarMenu />
                    </div>
                    <div className={classes.breadcrumbsContainer}>
                        <Breadcrumbs />
                    </div>
                    <div className={classes.viewContainer}>
                        {children}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PrivateLayoutBo;
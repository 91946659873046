import React, { useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Paper, TextField, InputAdornment, IconButton, Button, CircularProgress, Link, Dialog } from '@material-ui/core';
import { EmailRounded, LockRounded, Visibility, VisibilityOff } from '@material-ui/icons';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { AuthContext } from '../../../context/auth/auth-context.js';
import Styles from './style';

import { useApp } from '../../../context/app';




const useStyles = makeStyles(Styles);

const SignInPopup = ({ open }) => {

    const { signin, signInFederated } = useContext(AuthContext);

    const { setOpenPopupLogin, setOpenPopupRecoverPassword, setOpenPopupPasswordRequired } = useApp();

    const classes = useStyles();
    const { t } = useTranslation();
    const [values, setValues] = useState({ showPassword: false });


    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: async values => {
            try {
                const response = await signin(values.email, values.password);
                const { challengeName } = response
                if (challengeName) {
                    switch (challengeName) {
                        case 'NEW_PASSWORD_REQUIRED':
                            setOpenPopupLogin(false)
                            setOpenPopupPasswordRequired(true)
                            break;
                        default:
                            break;
                    }
                }
                setOpenPopupLogin(false)
                console.log(response)
            } catch (error) {
                throw error
            }
        },
    });



    const formikMicrosoft = useFormik({
        initialValues: {},
        onSubmit: async () => {
            try {
                await signInFederated("CycloidUsers", "app");
            } catch (error) {
                throw error
            }
        },
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    return (

        <Dialog
            className={classes.dialog}
            maxWidth="md"
            disableBackdropClick={false}
            onClose={() => setOpenPopupLogin(false)}
            open={open}
            aria-labelledby="popup-info-title"
            aria-describedby="popup-info-description"
        >
            <Paper className={classes.paper} elevation={0}>
                <div className={classes.title}>{t('login-text-title')}</div>
                <div className={classes.subtitle}>{t('signin-text-subtitle')}</div>
                <br />
                <form onSubmit={formik.handleSubmit} className={classes.form}>
                    <TextField
                        id="email"
                        type='email'
                        placeholder={t('signin-input-email')}
                        variant="outlined"
                        InputProps={{ startAdornment: <EmailRounded className={classes.icons} /> }}
                        defaultValue={formik.initialValues.email}
                        onChange={formik.handleChange}
                        required
                    />
                    <TextField
                        id="password"
                        type={values.showPassword ? 'text' : 'password'}
                        placeholder={t('signin-input-password')}
                        variant="outlined"
                        InputProps={{
                            startAdornment: <LockRounded className={classes.icons} />,
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end">
                                    {values.showPassword ? <Visibility className={classes.icons} /> : <VisibilityOff className={classes.icons} />}
                                </IconButton>
                            </InputAdornment>
                        }}
                        onChange={formik.handleChange}
                        required
                    />
                    <Button type='submit' variant="contained" disableElevation disabled={formik.isSubmitting}>{
                        formik.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('signin-btn-text')
                    }</Button>
                </form>
                <span className={classes.subtext}>{t('signin-text-subtext-1')}&nbsp;&nbsp;
                    <span component="button" onClick={() => {
                        setOpenPopupLogin(false)
                        setOpenPopupRecoverPassword(true)
                    }} className={classes.anchor}>
                    {t('signin-text-subtext-2')}
                </span>
                </span>
                {/*SIGNIN FEDERATION - OFFICE 365
                <Button onClick={formikMicrosoft.handleSubmit} variant="contained" disableElevation disabled={formikMicrosoft.isSubmitting}>{
                    formikMicrosoft.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('signin-federation-office-btn-text')
                }</Button>
                */}
            </Paper>
        </Dialog>
    );
}

export default SignInPopup;

